<template>
  <section
    id="ft-thx-banner"
    class="ft-thx-banner-section position-relative"
    data-background="../../assets/imgs/bg/banner-bg.jpg"
  >
    <div class="background_overlay"></div>
    <div class="container">
      <div class="ft-thx-banner-content position-relative">
        <div class="row">
          <div class="col-lg-6">
            <div class="ft-thx-banner-text headline pera-content">
              <div class="banner-slug text-uppercase">You are Welcome To</div>
              <h1>WINWIN LINES LIMITED</h1>
              <p>
                WINWIN LINES LIMITED is an Indian container line operator that
                manages fleet of containers in India and Indian sub-continent.
                The Company has unique experience with effective fleet
                management in India, who is quick and prompt for responding to
                each and every customer's query.
              </p>
              <div class="ft-thx-banner-btn d-flex">
                <router-link to="/about">About Us</router-link>
                <router-link to="/services">Service</router-link>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6">
            <div class="ft-thx-banner-courier-form">
              <form action="#">
                <div class="row">
                  <div class="col-md-12">
                    <div class="wc-input">
                      <span>Freight type:</span>
                      <div class="wc-select position-relative">
                        <select name="#">
                          <option value="#">Seelct</option>
                          <option value="#">Air Freight</option>
                          <option value="#">Sea Freight</option>
                          <option value="#">Road Freight</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="wc-input">
                      <span>Load:</span>
                      <div class="wc-select position-relative">
                        <select name="#">
                          <option value="#">Seelct</option>
                          <option value="#">500kg</option>
                          <option value="#">1000kg</option>
                          <option value="#">1500kg</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div
                      class="wc-input-range"
                      data-background="../../assets/imgs/bg/wc-f-bg.html"
                    >
                      <span>DIST (Miles):</span>
                      <div
                        class="wc-input-range-area d-flex align-items-center"
                      >
                        <div id="slider-range" class="range-bar"></div>
                        <div class="range-value clearfix">
                          <input type="text" id="amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="wc-input">
                      <span>Your name:</span>
                      <div class="wc-text-input position-relative">
                        <input type="text" placeholder="Jhon Doe.." />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="wc-input">
                      <span>Your Mail:</span>
                      <div class="wc-text-input position-relative">
                        <input type="email" placeholder="Example@mail.com" />
                      </div>
                    </div>
                  </div>
                </div>
                <button class="text-uppercase" type="submit">
                  Submit request
                </button>
              </form>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>

  <!-- <section
    id="ft-banner"
    class="ft-banner-section"
    data-background="../../assets/imgs/bg/banner-bg.jpg"
  >
    <div class="ft-banner-content">
      <div class="container">
        <div class="ft-banner-text-content headline pera-content text-center">
          <h1 class="cd-headline letters scale">
            Shipping Products
            <span class="cd-words-wrapper">
              <b class="is-visible">WorldWide</b>
              <b>Safely</b>
              <b>Everywhere</b>
            </span>
          </h1>
          <p>
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Quis ipsum suspendisse ultrices gravida.
          </p>
          <div
            class="ft-banner-btn-wrapper d-flex justify-content-center align-items-center"
          >
            <div class="ft-banner-btn">
              <a
                class="d-flex justify-content-center align-items-center"
                href="service.html"
                >Our Services</a
              >
            </div>
            <div class="ft-banner-video-btn">
              <a
                class="video_box"
                href="https://www.youtube.com/watch?v=C4jjFanHZo8"
              >
                <i class="fas fa-play"></i>
                <span>How It Work</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
