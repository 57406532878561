<template>
  <!-- Start of Footer  section ============================================= -->
  <footer id="pr-sv-footer" class="pr-sv-footer-section">
    <div class="container">
      <div class="pr-sv-footer-widget-wrap">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div
              class="pr-sv-footer-widget headline clearfix pera-content ul-li-block"
            >
              <div class="pr-sv-about-widget">
                <h3 class="widget-title">About</h3>
                <p>
                  {{ mainData.cpmpany_tagline }} 
                </p>
                <div class="ft-thx-btn text-center">
                  <router-link
                    class="d-flex justify-content-center align-items-center"
                    to="/conactus"
                    >Get a Quote</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="pr-sv-footer-widget headline clearfix pera-content ul-li-block"
            >
              <div class="pr-sv-menu-widget">
                <h3 class="widget-title">Links</h3>
                <ul>
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/about">About WINWIN</router-link></li>
                  <li><router-link to="/services">Services</router-link></li>
                  <li><router-link to="/tracking">Tracking</router-link></li>
                  <li><router-link to="/careers">Careers</router-link></li>
                  <li><router-link to="/gallery">Gallery</router-link></li>
                  <li><router-link to="/conactus">Contact Us</router-link></li>
                  <li><router-link to="/presence-india">India Network</router-link></li>
                  <li><router-link to="/presence-overseas">Overseas Presence</router-link></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="pr-sv-footer-widget headline clearfix pera-content ul-li-block"
            >
              <div class="pr-sv-gallery-widget">
                <h3 class="widget-title">Image Gallery</h3>
                <ul class="zoom-gallery">
                  <li>
                    <a
                      href="../../../public/assets/imgs/gallery/gl1.jpg"
                      data-source="../../../public/assets/imgs/gallery/gl1.jpg"
                      ><img src="../../../public/assets/imgs/gallery/gl1.jpg" alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="../../../public/assets/imgs/gallery/gl2.jpg"
                      data-source="../../../public/assets/imgs/gallery/gl2.jpg"
                      ><img src="../../../public/assets/imgs/gallery/gl2.jpg" alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="../../../public/assets/imgs/gallery/gl3.jpg"
                      data-source="../../../public/assets/imgs/gallery/gl3.jpg"
                      ><img src="../../../public/assets/imgs/gallery/gl3.jpg" alt=""
                    /></a>
                  </li>
                  <li>
                    <a
                      href="../../../public/assets/imgs/gallery/gl4.jpg"
                      data-source="../../../public/assets/imgs/gallery/gl4.jpg"
                      ><img src="../../../public/assets/imgs/gallery/gl4.jpg" alt=""
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="pr-sv-footer-widget headline clearfix pera-content ul-li-block"
            >
              <div class="pr-sv-contact-widget">
                <h3 class="widget-title">Contact Info</h3>
                <ul>
                  <li>
                    <i class="fas fa-phone"></i> <span> {{ mainData.contact_no }} </span>
                  </li>
                  <li>
                    <i class="fas fa-envelope"></i>
                    <span> {{ mainData.email }} </span>
                  </li>
                  <li>
                    <i class="fas fa-map-marker-alt"></i>
                    <span
                      >{{ mainData.title }}<br/>
                        {{ mainData.address2 }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyRight text-center p-4">
        Copyright © 2023 all rights reserved <router-link to="/">WINWIN LINES LIMITED</router-link> 
      </div>
    </div>
  </footer>
  <!-- End of Footer  section ============================================= -->
</template>

<script>
import data from '../../data/data.json'
export default {
  name: "Footer",
  data() {
    return {
      mainData: ""
    };
  },
  created(){
    this.mainData = data.companyDeatils;
  }
};
</script>

<style scoped></style>
