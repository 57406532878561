import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import JQuery from 'jquery'
window.$ = window.JQuery = JQuery

import VueSweetalert2 from 'vue-sweetalert2'; 
import swal from 'sweetalert2';
window.Swal = swal;

import '../src/assets/js/smtp.js'

createApp(App).use(router).use(VueSweetalert2).mount('#app')
