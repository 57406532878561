<template>
  <!-- Services Section Four -->
  <section class="ft3-services-section-four">
    <div class="auto-container">
      <div class="inner-container">
        <div class="clearfix">
          <!-- Service Block Three -->
          <div class="ft3-service-block-three col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div
                class="image-layer"
                style="background-image:url(../../assets/imgs/resource/service-5.jpg)"
              ></div>
              <h5><a href="javascript:void(0)">Liner Container</a></h5>
              <div class="text">
                With our experienced team of professionals, we are able to offer all kinds 
                  of damage repairs and all kind of container solutions.
              </div>
              <a href="javascript:void(0)" class="read-more">Read More</a>

              <!-- Overlay Box -->
              <div class="overlay-box">
                <div
                  class="overlay-image-layer"
                  style="background-image:url(../../assets/imgs/resource/service-5.jpg)"
                ></div>
                <div class="shape-layer"></div>
                <div class="overlay-inner">
                  <div class="content">
                    <div class="icon-box">
                      <!-- <span class="icon flaticon-boat"></span> -->
                      <i class="fal fa-container-storage"></i>
                    </div>
                    <h5><a href="javascript:void(0)">Liner Container</a></h5>
                    <div class="lower-text">
                      We have highly professional trained staff <br/>to perform all operations.
                    </div>
                    <router-link to="/linercontainer" class="more"
                      >Read More <span class="fa fa-angle-right"></span
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Service Block Three -->
          <div
            class="ft3-service-block-three active col-lg-4 col-md-6 col-sm-12"
          >
            <div class="inner-box">
              <div
                class="image-layer"
                style="background-image:url(../../assets/imgs/resource/service-5.jpg)"
              ></div>
              <h5><a href="javascript:void(0)">Tramp Container</a></h5>
              <div class="text">
                  With our experienced team of professionals, we are able to offer all kinds 
                  of damage repairs and all kind of container solutions.
              </div>
              <a href="javascript:void(0)" class="read-more">Read More</a>

              <!-- Overlay Box -->
              <div class="overlay-box">
                <div
                  class="overlay-image-layer"
                  style="background-image:url(../../assets/imgs/resource/service-5.jpg)"
                ></div>
                <div class="shape-layer"></div>
                <div class="overlay-inner">
                  <div class="content">
                    <div class="icon-box">
                      <!-- <span class="icon flaticon-boat"></span> -->
                      <i class="fal fa-container-storage"></i>
                    </div>
                    <h5><a href="javascript:void(0)">Tramp Container</a></h5>
                    <div class="lower-text">
                      We have highly professional trained staff <br/>to perform all operations.
                    </div>
                    <router-link to="/trampcontainer" class="more"
                      >Read More <span class="fa fa-angle-right"></span
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Service Block Three -->
          <div class="ft3-service-block-three col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div
                class="image-layer"
                style="background-image:url(../../assets/imgs/resource/service-5.jpg)"
              ></div>
              <h5><a href="javascript:void(0)">Reefer containers</a></h5>
              <div class="text">
                  With our experienced team of professionals, we are able to offer all kinds 
                  of damage repairs and all kind of container solutions.
              </div>
              <a href="javascript:void(0)" class="read-more">Read More</a>

              <!-- Overlay Box -->
              <div class="overlay-box">
                <div
                  class="overlay-image-layer"
                  style="background-image:url(../../assets/imgs/resource/service-5.jpg)"
                ></div>
                <div class="shape-layer"></div>
                <div class="overlay-inner">
                  <div class="content">
                    <div class="icon-box">
                      <i class="fal fa-container-storage"></i>
                      <!-- <span class="icon flaticon-boat"></span> -->
                    </div>
                    <h5><a href="javascript:void(0)">Reefer containers</a></h5>
                    <div class="lower-text">
                      We have highly professional trained staff <br/>to perform all operations.
                    </div>
                    <router-link to="/reefercontainers" class="more"
                      >Read More <span class="fa fa-angle-right"></span
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section Four -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
