<template>
    <mainSLide/>
    <aboutUs/>
    <services/>
    <!-- <videoSection/> -->
    <team/>
    <counter/>
    <services2/>
    <!-- <testimonial/> -->
    <!-- <projects/> -->
    <faq/>
    <!-- <blog/> -->
</template>

<script>
import mainSLide from '../components/mainSlide/mainSlide.vue'
import aboutUs from '../components/about/aboutUs.vue' 
import services from '../components/services/services.vue'
// import videoSection from '../components/videoSection/videoSection.vue'
import counter from '../components/counterSection/counter.vue'
import services2 from '../components/services/services2.vue'
import team from '../components/team/team.vue'
// import testimonial from '../components/testimonial/testimonial.vue'
// import projects from '../components/Projects/projects.vue'
import faq from '../components/FAQ section/faq.vue'
// import blog from '../components/blogSection/blog.vue'
export default {
  name: 'Home', 
    components: { 
      mainSLide,
      aboutUs,
      services,
      // videoSection,
      counter,
      services2,
      team,
      // testimonial,
      // projects,
      faq,
      // blog
    },
  data() {
    return{}
  }
}
</script>
