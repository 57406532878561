<template>
  <!-- <div id="preloader"></div> -->
  <div class="up">
    <a href="#" class="scrollup text-center"
      ><i class="fas fa-chevron-up"></i
    ></a>
  </div>
  <header id="pr-mark-header" class="pr-mark-main-header">
    <div class="container-fluid">
      <div
        class="pr-mark-header-top d-flex justify-content-between align-items-center position-relative"
      >
        <div class="pr-mark-header-top-cta ul-li">
          <ul>
            <li>
              <img src="../../../public/assets/imgs/icon/ic1.png" alt="" />
              {{ mainData.email }}
            </li>
            <li>
              <img src="../../../public/assets/imgs/icon/ic2.png" alt="" />
              {{ mainData.contact_no }}
            </li>
          </ul>
        </div>
        <div class="pr-mark-header-social d-flex">
          <a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
          <a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
          <a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com/company/winwin-lines-limited/" target="_blank"><i class="fab fa-linkedin"></i></a>
        </div>
      </div>
      <div
        class="pr-mark-main-navigation-wrapper d-flex justify-content-between position-relative"
      >
        <div class="pr-mark-brand-logo">
          <!-- WINWIN -->
          <router-link to="/"
            ><img :src="getItem(mainData.logo)" alt=""
          /></router-link>
        </div>
        <div class="pr-mark-navigation-menu d-flex align-items-center myMenu">
          <nav class="pr-main-navigation  clearfix ul-li">
            <ul id="pr-main-nav" class="nav navbar-nav clearfix">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about">About WINWIN</router-link></li>
              <li class="dropdown">
                <router-link to="/services">Services</router-link>
                <ul class="dropdown-menu clearfix">
                  <li>
                    <router-link to="/linercontainer"
                      >Liner Containers</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/trampcontainer"
                      >Tramp Containers</router-link
                    >
                  </li> -->
                  <li>
                    <router-link to="/reefercontainers"
                      >Reefer Containers</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/freightforwarding"
                      >Freight Forwarding</router-link
                    >
                  </li> -->
                  <li>
                    <router-link to="/salespurchase"
                      >Container Sale & Purchase</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/isotankcontainer"
                      >ISO Tank Containers</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:void(0)">Network</a>
                <ul class="dropdown-menu clearfix">
                  <li>
                    <router-link to="/presence-india"
                      >India Network</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/presence-overseas"
                      >Overseas Presence</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/subsidiaries"
                      >Our Subsidiaries</router-link
                    >
                  </li>
                </ul>
              </li>
              <!-- <li>
                <router-link to="/network">Network</router-link>
              </li> -->
              <li>
                <router-link to="/tracking">Tracking</router-link>
              </li>
              <li>
                <router-link to="/careers">Careers</router-link>
              </li>
              <li>
                <router-link to="/gallery">Gallery</router-link>
              </li>
              <li>
                <router-link to="/conactus">Contact Us</router-link>
              </li>
            </ul>
          </nav>
          <div class="pr-mark-btn text-center">
            <router-link class="d-flex justify-content-center align-items-center" to="/conactus"
              >Get A Quote</router-link
            >
          </div>
        </div>
      </div>
      <div class="mobile_menu position-relative">
        <div class="mobile_menu_button open_mobile_menu">
          <i class="fal fa-bars"></i>
        </div>
        <div class="mobile_menu_wrap">
          <div class="mobile_menu_overlay open_mobile_menu"></div>
          <div class="mobile_menu_content">
            <div class="mobile_menu_close open_mobile_menu">
              <i class="fal fa-times"></i>
            </div>
            <div class="m-brand-logo">
              <router-link to="/">
                <img :src="getItem(mainData.logo)" alt="" />
                <!-- <img src="../../assets/imgs/logo/logo2.png" alt=""/> -->
              </router-link>
            </div>
            <nav class="mobile-main-navigation  clearfix ul-li">
              <ul id="pr-main-nav" class="nav navbar-nav clearfix">
                <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about">About WINWIN</router-link></li>
              <li class="dropdown">
                <router-link to="/services">Services</router-link>
                <ul class="dropdown-menu clearfix">
                  <li>
                    <router-link to="/linercontainer"
                      >Liner Containers</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/trampcontainer"
                      >Tramp Containers</router-link
                    >
                  </li> -->
                  <li>
                    <router-link to="/reefercontainers"
                      >Reefer Containers</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/freightforwarding"
                      >Freight Forwarding</router-link
                    >
                  </li> -->
                  <li>
                    <router-link to="/salespurchase"
                      >Container Sale & Purchase</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/isotankcontainer"
                      >ISO Tank Containers</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:void(0)">Network</a>
                <ul class="dropdown-menu clearfix">
                  <li>
                    <router-link to="/presence-india"
                      >India Network</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/presence-overseas"
                      >Overseas Presence</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/subsidiaries"
                      >Our Subsidiaries</router-link
                    >
                  </li>
                </ul>
              </li>
              <!-- <li>
                <router-link to="/network">Network</router-link>
              </li> -->
              <li>
                <router-link to="/tracking">Tracking</router-link>
              </li>
              <li>
                <router-link to="/careers">Careers</router-link>
              </li>
              <li>
                <router-link to="/gallery">Gallery</router-link>
              </li>
              <li>
                <router-link to="/conactus">Contact Us</router-link>
              </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- /Mobile-Menu -->
      </div>
    </div>
  </header>
</template>

<script>
import data from "../../data/data.json";
export default {
  name: "Header",
  date() {
    return {
      mainData: "",
    };
  },
  created() {
    this.mainData = data.companyDeatils;
    console.log("this is company data", this.mainData);
  },
  methods: {
    getItem(pic) {
      if (pic) {
        return require("../../../public/assets/imgs/logo/" + pic);
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped></style>
