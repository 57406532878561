import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Frame from '../Layouts/Frame.vue'

const routes = [
  {
    path: "",
    component: Frame,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
        // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/services',
        name: 'Services', 
        component: () => import(/* webpackChunkName: "services" */ '../views/services.vue')
      },
      {
        path: '/linercontainer',
        name: 'Liner Container', 
        component: () => import(/* webpackChunkName: "linercontainer" */ '../views/service.vue'),
        meta: {
          reload: true
        }
      },
      {
        path: '/trampcontainer',
        name: 'Tramp Container', 
        component: () => import(/* webpackChunkName: "trampcontainer" */ '../views/service.vue'),
        meta: {
          reload: true
        }
      },
      {
        path: '/reefercontainers',
        name: 'Reefer Containers', 
        component: () => import(/* webpackChunkName: "reefercontainers" */ '../views/service.vue'),
        meta: {
          reload: true
        }
      },
      {
        path: '/freightforwarding',
        name: 'Freight Forwarding', 
        component: () => import(/* webpackChunkName: "freightforwarding" */ '../views/service.vue'),
        meta: {
          reload: true
        }
      },
      {
        path: '/salespurchase',
        name: 'Container Sale & Purchase', 
        component: () => import(/* webpackChunkName: "salespurchase" */ '../views/service.vue'),
        meta: {
          reload: true
        }
      },
      {
        path: '/isotankcontainer',
        name: 'ISO Tank Containers', 
        component: () => import(/* webpackChunkName: "isotankcontainer" */ '../views/service.vue'),
        meta: {
          reload: true
        }
      },
      {
        path: '/network',
        name: 'Network', 
        component: () => import(/* webpackChunkName: "network" */ '../views/network.vue')
      },
      {
        path: '/tracking',
        name: 'Tracking', 
        component: () => import(/* webpackChunkName: "tracking" */ '../views/tracking.vue')
      },
      {
        path: '/careers',
        name: 'Career', 
        component: () => import(/* webpackChunkName: "careers" */ '../views/careers.vue')
      },
      {
        path: '/gallery',
        name: 'Gallery', 
        component: () => import(/* webpackChunkName: "gallery" */ '../views/gallery.vue')
      },
      {
        path: '/conactus',
        name: 'Conact Us', 
        component: () => import(/* webpackChunkName: "conactus" */ '../views/conactus.vue')
      },
      {
        path: '/ajay-nair',
        name: 'Ajay Nair', 
        component: () => import(/* webpackChunkName: "ajay-nair" */ '../views/team.vue')
      },
      {
        path: '/edwin-alexander',
        name: 'Edwin Alexander', 
        component: () => import(/* webpackChunkName: "edwin-alexander" */ '../views/team.vue')
      },
      {
        path: '/moncy-george',
        name: 'Moncy George', 
        component: () => import(/* webpackChunkName: "moncy-george" */ '../views/team.vue')
      },
      {
        path: '/niraj-israni',
        name: 'Niraj Israni', 
        component: () => import(/* webpackChunkName: "niraj-israni" */ '../views/team.vue')
      },
      {
        path: '/presence-india',
        name: 'India Network', 
        component: () => import(/* webpackChunkName: "Presence India" */ '../views/indiabranches.vue')
      },
      {
        path: '/presence-overseas',
        name: 'Overseas presence', 
        component: () => import(/* webpackChunkName: "presence Overseas" */ '../views/overseas.vue')
      },
      {
        path: '/subsidiaries',
        name: 'Our Subsidiaries', 
        component: () => import(/* webpackChunkName: "Our Subsidiaries" */ '../views/subsidiaries.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // scrollBehavior (to, from, savedPosition) {
    // return desired position
    // console.log(to, from, savedPosition);
    return { top: 0 };
  }
})

export default router
