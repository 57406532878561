<template> 
  <section id="pr-sx-about-2" class="pr-sx-about-section-2">
    <div class="container">
      <div class="pr-sx-about-content">
        <div class="row">
          <div class="col-lg-6">
            <div class="pr-sx-about-text-area">
              <div
                class="pr-sx-secion-title headline pera-content about-content  wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <span class="pr-sx-title-tag position-relative"
                  >About Company</span
                > 
                <h2>Professional <span>Indian Container Line</span> Operator</h2>
                <p>
                  {{ row1 }}
                </p>
                <p>
                  {{ row2 }}
                </p>
              </div>
              <div
                class="pr-sx-about-feature-list ul-li-block  wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <ul v-if="val">
                  <li>
                    {{ row3 }}
                  </li>
                  <li>
                    {{ row4 }}
                  </li>
                   <li>
                    {{ row5 }}
                  </li>
                </ul>
              </div>
              <div
                class="ft-thx-btn d-flex  text-center wow flipInX"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <router-link class="d-flex justify-content-center align-items-center" to="/about" v-if="btnShow">Explore More</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="pr-sx-about-exp-wrapper position-relative  wow fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div class="pr-sx-about-img-area">
                <img :src="getItem(mainData.image)" alt="" />
              </div>
              <div class="pr-sx-about-exp d-flex align-items-center">
                <div class="pr-sx-about-exp-icon text-center">
                  <i class="fal fa-container-storage"></i> 
                </div>
                <div class="pr-sx-about-exp-text headline pera-content">
                  <h3><span>2550</span>+</h3>
                  <p>Product Delivered</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
</template>

<script> 
import data from '../../data/data.json'
export default {
  data() {
    return {
      mainData: "",
      val: false,
      btnShow: true,
      routeName: "",
      row1: "",
      row2: "",
      row3: "",
      row4: "",
      row5: ""
    };
  },
  mounted() {
    this.mainData = data.about2;
    this.row1 = this.mainData.desc[0].row1
    this.row2 = this.mainData.desc[1].row2
    this.row3 = this.mainData.desc[2].row3
    this.row4 = this.mainData.desc[3].row4
    this.row5 = this.mainData.desc[4].row5

    this.routeName = this.$route.name;
    if(this.routeName == "About"){ 
      this.val = true;
      this.btnShow = false;
    } else{
      this.val = false;
      this.btnShow = true;
    }
  },
  methods:{
    getItem(pic){
      if(pic) {
        return require("../../../public/assets/imgs/about/" + pic)
      } else {
        return null
      }
    }
  }
};
</script>
