<template>
  <!-- Team Section Two -->
  <section class="ft2-team-section-two">
    <div class="auto-container">
      <div class="sec-title-two style-two">
        <div class="title">Our expert Board of Directors</div>
        <h2>Board of Directors</h2>
      </div>
      <div class="descSection">
        <p><b>Company is promoted by...</b></p>
        <P><b>Mr Edwin Alexander, Mr Moncy George Puthenpurackal</b> and <b>Mr Niraj Ashok Israni</b>. Our Promoters collectively have rich experience and varied experience in logistic industry and are the key force beyond growth and development of our Company. Along with the Promoters and Key Managerial Personnel, presently we have a team around 85 employees on pan India level. This dedicated team of employees who have expertise in logistics support and handling cargo, work round the clock to cater every minute detail to meet the customers’ expectations.</P>
        <P>We believe in gaining customer satisfaction through process enhancements and constant innovation in our services and have received an ISO 9001: 2015 certification for providing logistics services pertaining to shipping agencies, freight forwarding, Logistics, NVOCC, Liner Activity and Supply Chain Management Services.</P>
      </div>
      <div class="row">
        <div class="col-lg-4" v-for="item in mainData" :key="item.id">
          <div class="ft2-team-block-two">
            <div class="inner-box">
              <div class="image">
                <img
                  :src="getItem(item.image)"
                  alt=""
                />
                <!-- Overlay Box -->
                <div class="overlay-box">
                  <div
                    class="overlay-image-layer"
                    style="background-image:url(../../../public/assets/imgs/resource/service-5.jpg)"
                  ></div>
                  <div class="shape-layer"></div>
                  <div class="overlay-inner">
                    <div class="content">
                      <div class="author-image">
                        <img
                          :src="getItem(item.image)"
                          alt=""
                        />
                      </div>
                      <div class="text">
                        experience and varied experience in logistic industry
                      </div>
                      <!-- Social Box -->
                      <ul class="social-box">
                        <li><a href="javascript:void(0)" class="fab fa-facebook-f"></a></li>
                        <li><a href="javascript:void(0)" class="fab fa-twitter"></a></li>
                        <li><a href="javascript:void(0)" class="fab fa-instagram"></a></li> 
                      </ul>
                      <!-- End Social Box -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="lower-content">
                <div class="content">
                  <h5><a href="javascript:void(0)">{{ item.name }}</a></h5>
                  <div class="designation">{{ item.post }}</div>
                  <div class="phone-number">
                    <a class="phone" href="javascript:void(0)"
                      >contact no</a
                    >
                  </div>
                </div>
                <router-link :to="item.route" class="more">Read More <span class="fa fa-angle-right"></span
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section Two -->
</template>

<script> 
import data from '../../data/data.json'
export default {
  data() {
    return {
      mainData: "",
      profileImage: ""
    };
  },
  created(){
    this.mainData = data.directors.team 
  },
  methods:{
    getItem(pic){
      if(pic) {
        return require("../../../public/assets/imgs/team/" + pic)
      } else {
        return null
      }
    }
  }
};
</script>
