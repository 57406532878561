<template>
  <!-- Start of Service  section
	============================================= -->
  <section id="pr-sv-service" class="pr-sv-service-section">
    <div class="container">
      <div
        class="pr-sx-secion-title headline pera-content text-center wow fadeInUp"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <span class="pr-sx-title-tag position-relative">Our Services</span>
        <h2>We Provide Exclusive Service For <br/><span>Container Line</span></h2>
        <p>
          At WINWIN, we believe in deliverables and our Endeavour is to continue the journey 
          to keep getting better and deliver better to our Clients, Vendors and Employees.
        </p>
      </div>
      <div class="pr-sv-service-content">
        <div class="row">
          <div
            class="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox boxMinHeight">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                <!-- <i class="flaticon-delivery-truck"></i> -->
                <i class="fal fa-container-storage"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><router-link to="/linercontainer">Liner Containers</router-link></h3>
                <p>
                  We are providing services to Europe, 
                  Middle East, Indian Sub-Continent Ports (ISC) and South East Asian port.
                </p>
                <router-link class="service-more" to="/linercontainer"
                  >Read More <i class="far fa-chevron-right"></i
                ></router-link>
              </div>
            </div>
          </div>
          <!-- <div
            class="col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                 
                <i class="fal fa-container-storage"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><router-link to="/trampcontainer">Tramp Containers</router-link></h3>
                <p>
                  We have team of experienced staff able to handle vessels from handy to ULCC size at 
                  any of the Indian Ports entirely Hassle free.
                </p>
                <router-link class="service-more" to="/trampcontainer"
                  >Read More <i class="far fa-chevron-right"></i
                ></router-link>
              </div>
            </div>
          </div> -->
          <div
            class="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="400ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox boxMinHeight">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                <!-- <i class="flaticon-boat"></i> -->
                <i class="fal fa-container-storage"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><router-link to="/reefercontainers">Reefer Containers</router-link></h3>
                <p>
                  We have got well maintained reefer containers which are being used to carry fresh vegetables, 
                  meat, sea food and fruits.
                </p>
                <router-link class="service-more" to="/reefercontainers"
                  >Read More <i class="far fa-chevron-right"></i
                ></router-link>
              </div>
            </div>
          </div>
          <!-- <div
            class="col-lg-4 col-md-6 wow fadeInUp"
            data-wow-delay="600ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                 
                <i class="fal fa-container-storage"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><router-link to="/freightforwarding">Freight Forwarding</router-link></h3>
                <p>
                  Our dedicated division of freight forwarding is doing marvelous job by handling some of big accounts. 
                  We have been working with majority of MLOs and enjoying very good rappor.
                </p>
                <router-link class="service-more" to="/freightforwarding"
                  >Read More <i class="far fa-chevron-right"></i
                ></router-link>
              </div>
            </div>
          </div> -->
          <div
            class="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="800ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox boxMinHeight">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                <!-- <i class="flaticon-train"></i> -->
                <i class="fal fa-container-storage"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><router-link to="/salespurchase">Container Sale & Purchase</router-link></h3>
                <p>
                  We have buyers and sellers in our panel those are interested in purchasing and selling dry & reefer 
                  containers at Mundra. We are involved in buying & selling containers at attractive price.
                </p>
                <router-link class="service-more" to="/salespurchase"
                  >Read More <i class="far fa-chevron-right"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="1000ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox boxMinHeight">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                <!-- <i class="flaticon-free-shipping"></i> -->
                <i class="fal fa-container-storage"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><router-link to="/isotankcontainer">ISO Tank Containers</router-link></h3>
                <p>
                  Our principals have fleet of ISO TANK CONTAINERS of various capacity ranging from 21000 liters to 
                  25000 liters to carry hazardous and non-hazardous cargoes.
                </p>
                <router-link class="service-more" to="/isotankcontainer"
                  >Read More <i class="far fa-chevron-right"></i
                ></router-link>
              </div>
            </div>
          </div>
          <!-- <div
            class="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="1200ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                <i class="fal fa-bullseye-arrow"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><a href="#">TRACK YOUR CARGO</a></h3>
                <p>
                  eiusmod incididunt ut labore velr etdolore magna aliqua
                  aliqua.
                </p>
                <a class="service-more" href="#"
                  >Read More <i class="far fa-chevron-right"></i
                ></a>
              </div>
            </div>
          </div> -->
          <!-- <div
            class="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="1400ms"
            data-wow-duration="1500ms"
          >
            <div class="pr-sv-service-innerbox">
              <div
                class="pr-sv-service-inner-icon d-flex align-items-center justify-content-center"
              >
                <i class="flaticon-delivery-truck"></i>
              </div>
              <div class="pr-sv-service-inner-text headline pera-content">
                <h3><a href="#">CARGO SERVICES</a></h3>
                <p>
                  eiusmod incididunt ut labore velr etdolore magna aliqua
                  aliqua.
                </p>
                <a class="service-more" href="#"
                  >Read More <i class="far fa-chevron-right"></i
                ></a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <!-- End of Service  section
	============================================= -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
