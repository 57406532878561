<template>
  <!-- Start of FAQ section
	============================================= -->
  <section id="ft-thx-faq" class="ft-thx-faq-section position-relative">
    <span class="ft-thx-shape1 position-absolute"
      ><img src="../../assets/imgs/bg/map2.png" alt=""
    /></span>
    <div class="container">
      <div class="ft-thx-faq-content">
        <div class="row">
          <div class="col-lg-6">
            <div class="ft-thx-faq-img">
              <img src="../../../public/assets/imgs/about/fq1.jpg" alt="" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="ft-thx-faq-text">
              <div
                class="pr-sx-secion-title headline pera-content  wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <span class="pr-sx-title-tag position-relative">FAQs</span>
                <h2>How can we <span>Help you!</span></h2>
              </div>
              <div class="ft-thx-featured-accordion">
                <div class="accordion" id="accordionExample">
                  <div
                    class="accordion-item headline-2 pera-content wow fadeInUp"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <span class="d-flex justify-content-center align-items-center"><i class="fas fa-container-storage smallIC"></i></span>
                        Quality Control System
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        We strive to improve our processes' consistency to guarantee 
                        that the final product meets or exceeds customers' expectations.
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item headline-2 pera-content wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span class="d-flex justify-content-center align-items-center"><i class="fas fa-container-storage smallIC"></i></span>
                        Highly Professional Staff
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        We have highly professional trained staff to perform all operations.
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item headline-2 pera-content wow fadeInUp"
                    data-wow-delay="400ms"
                    data-wow-duration="1500ms"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <span class="d-flex justify-content-center align-items-center"><i class="fas fa-container-storage smallIC"></i></span>
                        Container Storage Yard
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        We handle container storage for the import and export services of 
                        containers. Very special rates for Storage of Empty and Full Load 
                        all kinds of containers.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of FAQ section
	============================================= -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
