<template>
  <!-- Counter Section -->
  <section
    class="ft3-counter-section"
    style="background-image:url(../../assets/images/background/pattern-11.png)"
  >
    <div class="auto-container">
      <!-- Fact Counter -->
      <div class="ft-fact-counter style-two">
        <div class="row clearfix">
          <!-- Column -->
          <div class="column col-lg-3 col-md-6 col-ms-12">
            <div class="inner">
              <div class="content">
                <div class="icon flaticon-client"></div>
                <div class="count-outer count-box">
                  <span class="count-text counter" data-speed="3500" data-stop="2480">2480</span>+
                </div>
                <div class="counter-title">Satisfied Clients</div>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="column col-lg-3 col-md-6 col-ms-12">
            <div class="inner">
              <div class="content">
                <div class="icon flaticon-rocket"></div>
                <div class="count-outer count-box">
                  <span class="count-text counter" data-speed="2500" data-stop="1,50,358">1,50,358</span
                  >+
                </div>
                <div class="counter-title">Completed Delivery</div>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="column col-lg-3 col-md-6 col-ms-12">
            <div class="inner">
              <div class="content">
                <div class="icon flaticon-goal"></div>
                <div class="count-outer count-box">
                  <span class="count-text counter" data-speed="3500" data-stop="15">15</span
                  >+
                </div>
                <div class="counter-title">Awards Winner</div>
              </div>
            </div>
          </div>

          <!-- Column -->
          <div class="column col-lg-3 col-md-6 col-ms-12">
            <div class="inner">
              <div class="content">
                <div class="icon flaticon-team"></div>
                <div class="count-outer count-box">
                  <span class="count-text counter" data-speed="2500" data-stop="800">800</span
                  >k+
                </div>
                <div class="counter-title">Team Members</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Counter Section -->
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {};
  },
  computed() {
    this.counter()
  },
  methods: {
    counter() {
      $('.counter').counterUp({
        delay: 10,
        time: 2000
      });
      $('.counter').addClass('animated fadeInDownBig');
    },
  },
};
</script>
